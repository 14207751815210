import Vue from 'vue';
import VueRouter from 'vue-router';
import { mode } from 'crypto-js';
// import Ticket from '../views/Ticket/Ticket.vue';
// import Passenger from '../views/passeger/Passenger.vue';

Vue.use(VueRouter)
var mainTitle='湖南航空官网-机票预订_飞机票查询_航班动态_网上值机_特价机票查询-湖南航空股份有限公司';
var subTitle='湖南航空官网';
const routes = [
  {
    path: '/ticket',
    name: 'ticket',
    component: () => import('../views/Ticket/Ticket.vue'),
    meta:{
      title:mainTitle
    }
  },
  {
    path: '/passenger',
    name: 'passenger',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/passeger/Passenger.vue'),
    meta:{
      title:mainTitle
    }
  },
  {
    path: '/orderreview/:orderNo',
    name: 'orderreview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/pay/OrderReview.vue'),
    meta:{
      title:mainTitle
    }
  },
  {
    path: '/order/refund/:orderNo',
    name: 'orderrefund',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/order/OrderRefund.vue'),
    meta:{
      title:mainTitle
    }
  },
  {
    path: '/order/refund2/:orderNo',
    name: 'refund2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "passenger" */ '../views/order/OrderDetail.vue')
    component: () => import('../views/order/OrderRefund2.vue'),
    meta:{
      title:mainTitle
    }
  },
  {
    path: '/order/detail/:orderNo',
    name: 'orderdetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "passenger" */ '../views/order/OrderDetail.vue')
    component: () => import('../views/order/OrderDetail-out.vue'),
    meta:{
      title:mainTitle
    }
  },
  {
    path: '/order/details/:orderNo',
    name: 'orderdetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "passenger" */ '../views/order/OrderDetail.vue')
    component: () => import('../views/order/OrderDetail-new.vue'),
    meta:{
      title:mainTitle
    }
  },
  {
    path: '/doc/detail/:docid',
    name: 'docdetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/doc/detail.vue'),
    meta:{
      title:subTitle
    }
  },
  {
    path: '/doc/product/:docid',
    name: 'productdetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/doc/product.vue'),
    meta:{
      title:subTitle
    }
  },
  {
    path: '/doc/single/:aliasCode',
    name: 'single',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/doc/single.vue'),
    meta:{
      title:subTitle
    }
  },
  {
    path: '/service/checkin',
    name: 'checkin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/service/checkin.vue'),
    meta:{
      title:'网上值机-'+subTitle
    }
  },
  {
    path: '/service/checkin/list',
    name: 'checkin_list',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/service/checkin_list.vue'),
    meta:{
      title:'网上值机-'+subTitle
    }
  },
  {
    path: '/service/checkin/seatmap',
    name: 'checkin_seatmap',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/service/checkin_seatmap.vue'),
    meta:{
      title:'网上值机-'+subTitle
    }
  },
  {
    path: '/service/aoc',
    name: 'aoc',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/service/aoc.vue'),
    meta:{
      title:'航班动态-'+subTitle
    }
  },
  {
    path: '/service/checkticket',
    name: 'checkticket',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/service/checkticket.vue'),
    meta:{
      title:'客票验真-'+subTitle
    }
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/cooperation/cooperation.vue'),
    meta:{
      title:'湖南航空简介-'+subTitle
    }
  },  
  {
    path: '/company/:aliasCode',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/company/common.vue'),
    meta:{
      title:subTitle
    }
  },  
  {
    path: '/pss/service',
    name: 'notice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/pss/notice.vue'),
    meta:{
      title:'旅客服务-'+subTitle
    }
  },
  {
    path: '/pss/promotion',
    name: 'promotion',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/pss/promotion.vue'),
    meta:{
      title:'优惠促销-'+subTitle
    }
  },
  {
    path: '/pss/news',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/pss/news.vue'),
    meta:{
      title:'新闻公告-'+subTitle
    }
  },
  {
    path: '/member/orderlist',
    name: 'member',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/member/orderlist.vue'),
    meta:{
      title:'订单列表-'+subTitle
    }
  },
  {
    path: '/member/frequentflyer',
    name: 'frequentflyer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/member/FrequentFlyer.vue'),
    meta:{
      title:mainTitle
    }
  },
  {
    path: '/member/userinfo',
    name: 'userinfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/member/userinfo.vue'),
    meta:{
      title:'用户信息-'+subTitle
    }
  },
  {
    path: '/member/verify',
    name: 'member',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/member/Verify.vue'),
    meta:{
      title:mainTitle
    }
  },
  {
    path: '/member/memberHand',
    name: 'member',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/member/memberHand.vue'),
    meta:{
      title:mainTitle
    }
  },
  {
    path: '/member/changepwd',
    name: 'changepwd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/member/changepwd.vue'),
    meta:{
      title:mainTitle
    }
  },
  {
    path: '/reg',
    name: 'reg',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/user/reg.vue'),
    meta:{
      title:'会员注册-'+subTitle
    }
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/user/login.vue'),
    meta:{
      title:'会员中心-'+subTitle
    }
  },
  {
    path: '/',
    name: 'index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/index/index-new.vue'),
    meta:{
      title:'湖南航空官网-机票预订_飞机票查询_航班动态_网上值机_特价机票查询_湖南航空股份有限公司'
    }
  }
]

const router = new VueRouter({
  routes:routes,
  mode:'history'
},

)

export default router
