import { axios } from '@/utils/request'
import Vue from "vue";
import {
  SELECT_AIRPORTS  
} from "@/store/mutation-types";

export function getNewsTopN (parameter) {
  return axios({
    url: 'news/news/toplistbyaliascode',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function getNewsPicTopN (parameter) {
  return axios({
    url: 'news/news/toplistpicbyaliascode',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function getAdList (parameter) {
  return axios({
    url: 'ad/adlist',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}
export function getAdListByManage (parameter) {
  return axios({
    url: 'icon/queryIcon',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function getOneById (parameter) {
  return axios({
    url: 'news/news/getone',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function newslist (parameter) {
  return axios({
    url: 'news/news/list',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}
export function columnslist (parameter) {
  return axios({
    url: 'news/column/list',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}


export function fetchCheckinFlightList(params){
  let url = `check_in/detr?certNo=${params.certNo}&certType=${params.certType}&passengerName=${params.passengerName}&mobile=${params.mobile}`
  return axios({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(params)
  })
}

/**
 * 选座值机取消值机接口
 * @param  object params 提交取消值机数据
 * @return Promise       接口返回的JSON格式数据
 */
export function cancelCheckin(params) {
  let url = `check_in/cancel`
  return axios({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(params)
  })
}

/**
 * 选座值机查询接口
 * @param  object params 选座值机行程查询参数
 * @return Promise       接口返回的JSON格式数据
 */
export function fetchCheckinQuery(params) {
  let url = `check_in/sypr?dptAptCode=${params.dptAptCode}&arvAptCode=${params.arvAptCode}&flightDate=${params.flightDate}&flightNo=${params.flightNo}&ticketNo=${params.ticketNo}`
  return axios({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(params)
  })
}

/**
 * 选座值机座位图接口
 * @param  object params 选座值机座位图查询参数
 * @return Promise       接口返回的JSON格式数据
 */
export function fetchCheckinSeats(params) {
  let url = `check_in/querySeat?flightDateStr=${params.flightDate}&flightNo=${params.flightNo}&depAirportCode=${params.depAirportCode}&arrAirportCode=${params.arrAirportCode}&cabinClass=${params.cabinClass}`
  return axios({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(params)
  })
}

/**
 * 选座值机值机接口
 * @param  object params 提交值机数据
 * @return Promise       接口返回的JSON格式数据
 */
export function submitCheckin(params) {
      return axios({
        url: 'check_in/confirm',
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(params)
      })
}

export function getHotAirlines (parameter) {
  return axios({
    url: 'route/hotairlines',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function pssgroup (parameter) {
  return axios({
    url: 'news/news/pssgroup',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}


export function getOneByAliasCode (parameter) {
  return axios({
    url: 'news/news/getonebyaliascode',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function getFlightList (parameter) {
  return axios({
    url: 'route/searchflight',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function getLowestSchedule (parameter) {
  return axios({
    url: 'route/lowestschedule',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function getLowestFlights (parameter) {
  return axios({
    url: 'route/searchLowestFlights',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function getDynamicData (parameter) {
  return axios({
    url: 'flightdynamicdata/queryList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}
export function getDynamicDetail (parameter) {
  return axios({
    url: 'route/detailDynamicV2',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function validateticket (parameter) {
  return axios({
    url: 'route/validateticket',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}


export function searchtraveler (parameter) {
  return axios({
    url: 'wxapp/searchtraveler',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function bookOrder (parameter) {
  return axios({
    url: 'route/book',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function orderDetail (parameter) {
  return axios({
    url: 'route/orderdetail',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function orderCancel (parameter) {
  return axios({
    url: 'route/ordercancel',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function refundFeeAction (parameter) {
  return axios({
    url: 'route/refundfee',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}


export function refundAction (parameter) {
  return axios({
    url: 'route/refundticket',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}


export function orderList (parameter) {
  return axios({
    url: 'route/orderlist',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function login (parameter) {
  return axios({
    url: 'user/login',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function loginOut (parameter) {
  return axios({
    url: 'user/loginOut',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function regist (parameter) {
  return axios({
    url: 'user/register',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function userinfo (parameter) {
  return axios({
    url: 'user/userInfo',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}
export function updateuser (parameter) {
  return axios({
    url: 'user/updateuser',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function verify (parameter) {
  return axios({
    url: 'user/verify',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function changePwd (parameter) {
  return axios({
    url: 'user/changepwd',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function fetchPhoneValidateCode (parameter) {
  return axios({
    url: 'user/sendValidateCodeAfterPicCode',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function fetchAirports(){
  let allAirports=Vue.ls.get(SELECT_AIRPORTS);
  if(allAirports!=null && allAirports){
    return allAirports.allAirports;
  }
  else{
    return []
  }
}

export function fetchAirportsPannelData(parameter){
  return axios({
    url: 'route/airportspannel',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}


export function synServerDate(parameter){
  return axios({
    url: 'route/synServerDate',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

export function queryDict(params) {
  let url = `common/getDicConfigByKey?key=${params.key}`
  return axios({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(params)
  })
}

export function fetchHotAirports(){
  return fetchAirports();
}


export function queryMemberRight (parameter) {
  return axios({
    url: 'user/queryMemberRight',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}
/* 查询通告 */
export function queryFlightNotices (parameter) {
  return axios({
    url: 'notice/queryFlightNotices',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(parameter)
  })
}

/**
 * 获取预定须知
 * @param {*} params 
 * @returns 
 */
export function queryNoticeList(params) {
  let url = `common/getCacheDicConfigByKey?key=${params.key}`
  return axios({
    url: url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(params)
  })
}

// 获取国家码
export function getCountryCodes(params) {
  let url = `common/countryCode`
  return axios({
    url: url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

// 获取证件类型
export function getCertType(params) {
  let url = `common/certType`
  return axios({
    url: url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
  })
}

// 转拼音
export function chineseToPinyin(params) {
  let url = `common/chinese2Pinyin`
  return axios({
    url: url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}