<template>
    <div class="notice-content" v-if="showNotice">
        <div class="flex content-wrapper">
            <el-tabs :activeKey='activeKey' class="notice-tabs" @change="changeTab">
                <el-tab-pane v-for="(item, idx) in notice" :key="idx" :label="item.title" :name="String(idx)">
                    <span class="notice-tabs-content" v-html="item.content">
                    </span>
                </el-tab-pane>
            </el-tabs>
            <div class="confirm flex">
                <div class="confirm-btn" @click="confirm">知道了</div>
            </div>
            <img src="../assets/mark-close.png" class="close-icon" @click="showNotice = false"/>
        </div>
    </div>
</template>
<script>
import { EventBus } from "@/api/event-bus.js";
import { queryFlightNotices, queryDict } from "@/api/newsapi";
export default {
  name: "Notice",
  created() {
    this.getContent("NOTICE_DEFAULT_CONTENT");
    this.getTitle("NOTICE_DEFAULT_TITLE");
    EventBus.$on("hideNotice", msg => {
      this.showNotice = false;
    });
    EventBus.$on("showNotice", msg => {
      this.queryFlightNotices(msg.flights, msg.noticePage);
    });
  },
  watch: {
    showNotice(nval, oval) {}
  },
  data() {
    return {
      activeName: "",
      showNotice: false,
      activeKey: "0",
      defaultNotices: [
        {
          airportCode: "WUX",
          content: "",
          noticePage: "BOOK1,BOOK1.5,ORDER_DETAIL,JOURNEY_DETAIL",
          noticeType: "YQ",
          title: "疫情出行提醒",
          travelType: ""
        }
      ],
      notice: [] // 通告列表
    };
  },
  methods: {
    queryFlightNotices(flights, noticePage) {
      let params = {
        noticePage: noticePage || "JOURNEY_DETAIL",
        // 必传, BOOK1.5--BOOK1.5页面, ORDER_DETAIL--订单详情页JOURNEY_DETAIL--行程详情页
        flights: flights
      };
      queryFlightNotices({ t: params }).then(res => {
        if (res.success) {
          this.activeKey = "0";
          this.notice = res.data.notices && res.data.notices.length ? res.data.notices : [];
          if(this.notice.length>0){
            this.showNotice = true;
          }else{
            this.showNotice = false;
          }
          EventBus.$emit('hasFlightNotice',this.showNotice);
        } else {
          this.$message.error(res.errorMessage);
        }
      });
    },
    handleClick(tab, event) {},
    confirm() {
      this.showNotice = false;
    },
    changeTab(idx) {
      this.activeKey = idx;
    },
    async getContent(key) {
       await queryDict({ key: key }).then(res => {
        if (res.data) {
          this.defaultNotices[0].content = res.data.replace(/\\n/g, "<br/>");
        }
      });
    },
    async getTitle(key) {
       await queryDict({ key: key }).then(res => {
        if (res.data) {
          this.defaultNotices[0].title = res.data.replace(/\\n/g, "<br/>");
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.notice-content {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  z-index: 100000;
  .content-wrapper {
    top: 50%;
    width: 700px;
    position: fixed;
    margin: 0 auto;
    left: 50%;
    margin-top: -215px;
    margin-left: -350px
  }
  .notice-tabs {
    height: 430px;
    width: 700px;
    position: relative;
    background: #fff;
    &-content {
      font-size: 16px;
      color: #666666;
      // line-height: 21px;
      height: 250px;
      display: inline-block;
      padding: 0 32px;
      width: 630px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm {
  position: absolute;
  bottom: 0;
}
.confirm-btn {
  width: 160px;
  height: 40px;
  background: #d70038;
  border-radius: 4px;
  font-size: 18px;
  color: #ffffff;
  line-height: 40px;
  position: absolute;
  bottom: 24px;
  text-align: center;
  cursor: pointer;
}
.close-icon {
  width: 12px;
  height: 12px;
  position: absolute;
  z-index: 9999;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
</style>

