export const ACCESS_TOKEN = 'Access-Token'
export const SELECT_FLIGHT = 'SELECT-FLIGHT'
export const SELECT_AIRPORTS = 'SELECT-AIRPORTS'
export const SEARCH_FILTER = 'SEARCH_FILTER'
export const LOGIN_REDIRECT_ROUTE = 'LOGIN_REDIRECT_ROUTE'
export const CURRENT_ORDER = 'CURRENT_ORDER'
export const CURRENT_USER = 'CURRENT_USER'
export const SIDEBAR_TYPE = 'SIDEBAR_TYPE'
export const DEFAULT_THEME = 'DEFAULT_THEME'
export const DEFAULT_LAYOUT_MODE = 'DEFAULT_LAYOUT_MODE'
export const DEFAULT_COLOR = 'DEFAULT_COLOR'
export const DEFAULT_COLOR_WEAK = 'DEFAULT_COLOR_WEAK'
export const DEFAULT_FIXED_HEADER = 'DEFAULT_FIXED_HEADER'
export const DEFAULT_FIXED_SIDEMENU = 'DEFAULT_FIXED_SIDEMENU'
export const DEFAULT_FIXED_HEADER_HIDDEN = 'DEFAULT_FIXED_HEADER_HIDDEN'
export const DEFAULT_CONTENT_WIDTH_TYPE = 'DEFAULT_CONTENT_WIDTH_TYPE'
export const DEFAULT_MULTI_TAB = 'DEFAULT_MULTI_TAB'

export const CONTENT_WIDTH_TYPE = {
  Fluid: 'Fluid',
  Fixed: 'Fixed'
}
