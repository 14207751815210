<template>
  <div class="footer" style="margin-top:10px;">
    <div class="footer-links">
      <ul>
        <li class="copy-li" style="width: 32.6667%;">
          <dl>
            <img
              src="../assets/logo-new-black-2.png"
              alt
              style="height: 50px;width:223px;
              padding-top: 5px;"
            />
            <dt style="color: #4D4E4E;">共享天空 共享世界 共享美好旅程</dt>
            <dd>Copyright @ {{ year }} 湖南航空股份有限公司</dd>
            <dd>All rights reserved.</dd>

            <dd style="line-height:1;height:auto">
              <a
                target="_blank"
                href="https://beian.miit.gov.cn/"
                style="display:inline-block;color:;text-decoration:none;height:20px;line-height:20px;"
              >
                <p
                  style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 0px;display: inline;ine-height: 35px;"
                >
                  {{ filingNoDes }}
                </p>
              </a>
            </dd>
            <dd style="line-height:1">
              <a
                target="_blank"
                href="/doc/detail/163"
                style="display:inline-block;color:;text-decoration:none;height:20px;line-height:20px;"
              >
                <p
                  style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 0px;display: inline;ine-height: 35px;"
                >
                  增值电信业务经营许可证编号：湘B2-20210323
                </p>
              </a>
              <a
                  target="_blank"
                  href="/doc/detail/405"
                  style="display:inline-block;color:;text-decoration:none;height:20px;line-height:20px;"
              >
                <p
                    style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 0px;display: inline;ine-height: 35px;"
                >
                  营业执照
                </p>
              </a><a
                target="_blank"
                href="/doc/detail/406"
                style="display:inline-block;color:;text-decoration:none;height:20px;line-height:20px;"
            >
              <p
                  style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 6px;display: inline;ine-height: 35px;"
              >
                经营许可证
              </p>
            </a>
            </dd>
          </dl>
        </li>
        <li style="width: 13.6667%;">
          <dl>
            <dt style="color: #4D4E4E;">关于我们</dt>
            <dd v-for="item in aboutUsList" :key="item.url">
              <a target="_blank" :href="item.url">{{ item.label }}</a>
            </dd>
          </dl>
        </li>
        <li style="width: 13.6667%;">
          <dl>
            <dt style="color: #4D4E4E;">旅客服务</dt>
            <dd v-for="item in serviceList" :key="item.url">
              <a target="_blank" :href="item.url">{{ item.label }}</a>
            </dd>
          </dl>
        </li>
        <li style="width: 18.6667%;">
          <dl>
            <dt style="color: #4D4E4E;">友情链接</dt>
            <dd v-for="item in friendList" :key="item.url">
              <a target="_blank" :href="item.url">{{ item.label }}</a>
            </dd>
          </dl>
        </li>
        <li class="logos-qr">
          <figure>
            <div class="footer-img">
              <img
                style="width:100px;height:100px;margin-top:10px"
                src="../assets/weixin-zhaopin.png"
                alt
              />
              <img
                style="width:100px;height:100px;margin-top:10px;margin-left:55px;"
                src="../assets/weixin-info.jpg"
                alt
              />
              <img
                style="margin-right: 10px;margin-left:55px;width:120px;height:120px"
                src="../assets/wxmp.png"
                alt
              />
            </div>
            <div class="footer-tip" style="margin-top:10px">
              <div style="margin-left: 10px;">湖南航空HR</div>
              <div>湖南航空官方微信</div>
              <div style="text-align:center">湖南航空官方小程序</div>
            </div>
          </figure>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
const filingNoDesMap = {
  "www.redair.cn": "滇ICP备15007302号-1",
  "www.a6air.com": "湘ICP备2021001199号-1",
};
export default {
  props: ["activeIndex"],
  data() {
    return {
      input: "",
      filingNoDes: filingNoDesMap[location.hostname] || "湘ICP备2021001199号-1",
      aboutUsList: [
        { label: '湖南航空简介', url: '/company/introduction' },
        { label: '企业文化', url: '/company/culture' },
        { label: '广告招商', url: '/company/advertising' },
        { label: '联系我们', url: '/company/contact' },
        { label: '招聘信息', url: 'http://hnhk.17u.cn/recruit/portalkg/#/' },
      ],
      serviceList: [
        { label: '运输总条件', url: '/doc/single/transport_protocol' },
        { label: '危险品运输', url: '/doc/single/dangerous' },
        { label: '客票使用条件', url: '/doc/single/ticket_conditions' },
        { label: '特殊旅客运输指南', url: '/doc/single/health_notice' },
        { label: '用户注册协议', url: '/doc/single/user-regist' },
      ],
      friendList: [
        { label: '同程旅行', url: 'http://www.ly.com' },
        { label: '鸿程分销平台', url: 'https://adc.a6air.com/index' },
      ]
    };
  },
  mounted: function() {
    var notifyStatus = Vue.ls.get("cookienotify");
    console.log(notifyStatus);
    // notifyStatus='no';
    if ((notifyStatus == null || notifyStatus != "yes") && this.isPC()) {
      var notifyInstance = this.$notify({
        title: "",
        message:
          "为了提供个性化的展示与改善您的浏览体验，本网站使用cookie。继续浏览我们的网站，即表示您同意我们的 <a href='/doc/single/cookie' style='color:red'><strong>cookie政策</strong></a>以及<a href='/doc/single/privacy' style='color:red'><strong>隐私条款</strong></a>。 ",
        position: "bottom-right",
        duration: 0,
        dangerouslyUseHTMLString: true,
        customClass: "bottomnotify",
        onClose: function() {
          Vue.ls.set("cookienotify", "yes", 60 * 60 * 1000);
        },
      });
    }
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
  methods: {
    isPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
  },
};
</script>
<style scoped lang="less">
.footer {
  /* background-image: url("../assets/bottom_1.png");
   background-repeat: no-repeat; */
  background-color: #f7f7f7;
}
.footer h5 span {
  margin: 0 8px;
}
.footer h5 {
  text-align: center;
  color: #f5f5f5;
}
.footer h5 a:hover {
  color: #ff352e;
}
.footer h5 a {
  color: #f5f5f5;
}
.copy-right > div {
  display: block;
  line-height: 30px;
  margin: auto;
  width: 1200px;
}
.copy-right {
  /* background-color: #363b41;  */
  /* background-image: url("../assets/bottom_1.png");
   background-repeat: no-repeat;  */
  height: 40px;
  line-height: 35px;
  background-color: #5c6063;
}
.logos-qr {
  //padding-left: 30px;
  margin-top: 50px;
}
.logos-qr p {
  /* margin-top: 16px; */
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  color: #fff;
  text-align: left;
}
dl dd {
  height: 28px;
  line-height: 28px;
  font-size: 12px;
  cursor: pointer;
}
dl dd a {
  color: #666666;
  transition: all 0.3s;
}
dl dt a {
  color: #666666;
  transition: all 0.3s;
}
dl dd a:hover,
dl dt a:hover {
  color: #ff352e;
}
dl dt {
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 700;
  height: 44px;
  line-height: 44px;
  color: #ffffff;
}
.footer .footer-links > ul {
  display: flex;
  margin: auto;
  width: 1200px;
  padding: 16px 0;
}
.footer-links {
  /* background-color: #464b53;
  border-bottom: 1px solid #505764; */
  height: 260px;
  color: #232323;
}
.footer {
  letter-spacing: 1px;
}
.copy-li dd {
  color: #666666;
}
.footer-tip,
.footer-img {
  display: flex;
  font-size: 12px;
  // align-content: center;
  // justify-content: center;
  div {
    width: 150px;
    text-align: left;
  }
}
</style>
